// // import vinayakaTravels from './Images/vinayakaTravels.jpg'
// // import './App.css';

// // function App() {
// //   return (
// //     <div className="App">
// //       <header className="App-header">
// //         <img src={vinayakaTravels} className="App-logo" alt="logo" />
// //         {/* <p>
// //           Edit <code>src/App.js</code> and save to reload.
// //         </p>
// //         <a
// //           className="App-link"
// //           href="https://reactjs.org"
// //           target="_blank"
// //           rel="noopener noreferrer"
// //         >
// //           Learn React
// //         </a> */}
// //       </header>
// //     </div>
// //   );
// // }

// // export default App;

// import React from "react";
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import './App.css';
// import Header from "./Components/Header";
// import Footer from "./Components/Footer";
// import Home from "./Components/Home";



// // const routes = [
// //   { url: "home", component: Home },
// //   { url: "", component: Home },
// // ];

// const App = () => (
//   <div>
//   <Header />
//   <Router>
   
//     <Routes>
//     {/* {routes.map((data, i) => (
//                 <Route
//                   key={i}
//                   exact
//                   path={`/${data.url}`}
//                   component={data.component}
//                 />
//               ))} */}
//       <Route path="/home" element={<Home />} />
//       {/* <Route path="/about" element={<About />} /> */}
//     </Routes>
    
    
//   </Router>
//   <Footer />
//   </div>
// );

// export default App;

import React, { useState } from "react";
import Footer from "./Components/Footer2";
// import Hero from "./Components/Hero";
// import Recommend from "./Components/Recommend";
// import ScrollToTop from "./Components/ScrollToTop";
// import Services from "./Components/Services";
// import Testimonials from "./Components/Testimonials";
// import scrollreveal from "scrollreveal";
import { Routes, Route } from "react-router-dom";
// import NavbarHook from "./Components/NavbarHook";
import "./App.css"
// import Stepper2 from "./Components/Stepper";
// import Form2 from "./Components/Form";
// import Navbar2 from "./Components/NavBar2"
import Temp from "./Components/Temp";
import Header from "./Components/Header";
import SubPackages from "./Components/SubPackages";
import PackageDetails from "./Components/PackageDetails";
import { Button } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import CircularProgress from '@mui/material/CircularProgress';
import NewForm from "./Components/NewForm";
import BookingDetails from "./Components/BookingDetails";
import AboutUs from "./Components/AboutUs";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TermsAndConditions from "./Components/TermsAndConditions";
import CancellationPolicy from "./Components/CancellationPolicy";

export default function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // useEffect(() => {
  //   const sr = scrollreveal({
  //     origin: "top",
  //     distance: "80px",
  //     duration: 2000,
  //     reset: true,
  //   });
  //   sr.reveal(
  //     `
  //       nav,
  //       #hero,
  //       #services,
  //       #recommend,
  //       #testimonials,
  //       footer
  //       `,
  //     {
  //       opacity: 0,
  //       interval: 300,
  //     }
  //   );
  // }, []);
  
  return (
    <>
    {/* <NavbarHook/> */}
    <Header isAuthenticated={isAuthenticated} authenticate={setIsAuthenticated}/>
    <LoadingOverlay
            active={isLoading}
            // spinner
            text={
              <p
                style={{
                  color: "black",
                }}
              >
                <div>
                  <Button
                    style={{
                      display:'flex', flexDirection:'row', alignItems:'center',
                      padding:'25px',
                      backgroundColor: "#FFFF",
                      color: "black",
                      borderColor: "white",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                    variant="primary"
                    disabled
                  >
                    {/* <Spinner
                      as="span"
                      animation="border"
                      size="md"
                      variant="primary"
                      role="status"
                      aria-hidden="true"
                    /> */}
                    <CircularProgress/>
                    <span>
                    &nbsp;&nbsp;Loading Please Wait...</span>
                  </Button>
                </div>
              </p>
            }
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(192,192,192,0.4)",
              }),
              spinner: (base) => ({
                ...base,
                width: "80px",
                "& svg circle": {
                  stroke: "rgb(136, 108, 192)",
                },
              }),
            }}
          >
    <main className="main-content">
      <Routes>
        <Route path="/" element={<Temp loader={setIsLoading} />} />
        <Route path="/tour-packages" element={<Temp loader={setIsLoading}  />} />
        {/* <Route path="/home" element={<Recommend loader={setIsLoading}  />} /> */}
        <Route path="/about-us" element={<AboutUs/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/cancellation-policy" element={<CancellationPolicy />} />
        <Route path="/form" element={<NewForm loader={setIsLoading} isAuthenticated={isAuthenticated} authenticate={setIsAuthenticated}/>} />
        <Route path="/tour-packages/package/:packageName/:pid" element={<SubPackages loader={setIsLoading}  />} />
        <Route path="/tour-packages/details/:packageName/:pid" element={<PackageDetails  loader={setIsLoading} />} />
        <Route path="/details" element={<BookingDetails loader={setIsLoading} />} />
      </Routes>
    </main>
    </LoadingOverlay>
    <Footer/>
  </>

  );
}

