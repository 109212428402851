import React, { useEffect, useState } from "react";
import { camelCase } from "../Utils/Utils";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
// import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { useNavigate } from "react-router-dom";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import DetailsCard from "./DetailsCard";
// import noImage2 from "../assets/noImage2.png";
import "./PackageDetailsPage.css";
import LoadingOverlay from "react-loading-overlay";
import { CircularProgress } from "@mui/material";
import PlansComponent from "./PlansComponent";

// import { Grid } from "@mui/material";

const PackageDetails = (props) => {
  // const {loader}= props;
  const [isLoading, setIsLoading] = useState(false);
  const [path, setPath] = useState(null);
  const [packageDetails, setPackageDetails] = useState(null);
  let navigate = useNavigate();

  useEffect(() => {
    let path = window.location.pathname;
    path = path.split("/");
    setPath(path[3]);
    console.log(path);
    var body = {
      subPackageID: path[4],
    };

    // let formData = new FormData();
    // formData.append("subPackageID", path[4]);
    loadPackageDetails(body);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getYoutubeEmbedUrl(url) {
    // Function to extract the video ID from the YouTube URL
    const extractVideoId = (youtubeUrl) => {
      const urlParams = new URLSearchParams(youtubeUrl.split("?")[1]);
      return urlParams.get("v");
    };
    // Extract video ID from provided URL
    const videoId = extractVideoId(url);
    // Check if video ID is valid
    if (!videoId) {
      return null; // Return null for invalid URLs
    }
    // Construct the embed URL using the extracted video ID
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return embedUrl;
  }

  const loadPackageDetails = (body) => {
    setIsLoading(true)
    moduleApi.postData(apiCall.getSubPackageDetails, body).then((response) => {
      if (response?.status === 200) {
        console.log(response);
        setPackageDetails(response.data);
        localStorage.setItem("packageInfo", JSON.stringify(response?.data?.packageInfo));
        setIsLoading(false);
        // let itineraryObj ={
        //   name: "itinerary",
        //   data: getItinerary(response.itinerary)
        // }
        // setItinerary({
        //   name: "itinerary",
        //   data: getItinerary(response.itinerary)
        // });
      }
    });
  };

  const handleBookNow = () => {
    console.log(packageDetails?.plans);
    localStorage.setItem("planDetails", JSON.stringify(packageDetails?.plans));
    navigate("/form")
  }

  // function getItinerary(data){
  //   return listItems = data.map(item =>
  //     <li>{item}</li>
  //   );
  // }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="title">
        {camelCase(path?.replaceAll("-", " "))}
      </div>
      <Container>
        {/* <div className="row"> */}
        {/* style={{ display: "flex", flexDirection: "row" }} */}
        {/* <Grid container spacing={2} >
        <Grid item xs={9}> */}
        {/* className=" col-lg-9 pdetails" */}{" "}
        
        <div className="container">
          {/* Package Details Section */}
          {packageDetails===null && <div style={{flex:'0 0 70%'}}></div>}
          <div className="package-details">
          <LoadingOverlay
            active={isLoading}
            // spinner
            text={
              <p
                style={{
                  color: "black",
                }}
              >
                <div>
                  <Button
                    style={{
                      display:'flex', flexDirection:'row', alignItems:'center',
                      padding:'25px',
                      backgroundColor: "#FFFF",
                      color: "black",
                      borderColor: "white",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                    variant="primary"
                    disabled
                  >
                    {/* <Spinner
                      as="span"
                      animation="border"
                      size="md"
                      variant="primary"
                      role="status"
                      aria-hidden="true"
                    /> */}
                    <CircularProgress/>
                    <span>
                    &nbsp;&nbsp;Please Wait...</span>
                  </Button>
                </div>
              </p>
            }
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(192,192,192,0.4)",
              }),
              spinner: (base) => ({
                ...base,
                width: "80px",
                "& svg circle": {
                  stroke: "rgb(136, 108, 192)",
                },
              }),
            }}
          >
            {packageDetails?.banners && (
              // <ImageScroller inputData={packageDetails?.banners} />
              <DetailsCard inputData={packageDetails} type="thumb" />
            )}
            {packageDetails?.packageInfo && (
              <DetailsCard inputData={packageDetails} type="packageInfo" />
            )}
            {packageDetails?.itenerary?.length > 0 && (
              <DetailsCard inputData={packageDetails} type="itenerary" />
            )}
            {packageDetails?.inclusions && (
              <DetailsCard inputData={packageDetails} type="inclusions" />
            )}
            {packageDetails?.exclusions && (
              <DetailsCard inputData={packageDetails} type="exclusions" />
            )}
            {packageDetails?.plans?.length > 0 && (
              <PlansComponent inputData={packageDetails} type="plans" />
            )}
            {packageDetails?.subPacks?.length > 0 && (
              <DetailsCard inputData={packageDetails} type="subPacks" />
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "10px",
                flexWrap: "wrap", // Allows videos to stack on small screens
              }}
            >
              {packageDetails?.videos &&
                packageDetails?.videos?.map((item, index) => (
                  <iframe
                    key={index}
                    style={{
                      borderRadius: "20px",
                      margin: "10px",
                      width: "100%", // Full width on smaller screens
                      maxWidth: "350px", // Limit width on larger screens
                    }}
                    height="250"
                    src={getYoutubeEmbedUrl(item?.videoLink)}
                    title={"video" + index}
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                ))}
            </div>
            </LoadingOverlay>
          </div>

          {/* Book Now Section */}
          <div className="book-now">
            <div style={{ boxSizing: "borderBox", marginTop: "0 !important" }}>
              <header
                className="section-header text-center"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  rowGap: "5px",
                }}
              >
                <h5
                  className="text-center headdingcolor"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <QuestionAnswerRoundedIcon style={{ marginRight: "5px" }} />
                  Have any Queries?
                </h5>

                <span className="line text-center"></span>
                <p
                  className="text-center black carsfont"
                  style={{ fontWeight: "600" }}
                >
                  Do not hesitate to contact us
                </p>
                <h3
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    color: "green",
                    cursor: "pointer",
                  }}
                >
                  <LocalPhoneRoundedIcon style={{ marginRight: "5px" }} />
                  9989023318
                </h3>
                {packageDetails?.subPacks?.length === 0 && <Button
                  style={{
                    color: "white",
                    marginTop: "10px",
                    fontSize: "medium",
                    fontWeight: "600",
                    fontFamily:
                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                  }}
                  className="me-2"
                  variant="success btn-rounded"
                  onClick= {()=> handleBookNow() }
                >
                  Book Now
                </Button>}
              </header>
            </div>
          </div>
        </div>
        {/* </div> */}
      </Container>
    </div>
  );
};

export default PackageDetails;

const Container = styled.footer`
  display: flex;
  justify-content: space-evenly;
  border-radius: 0.5rem;
  padding: 3rem;

  @media (min-width: 992px) {
    pdetails {
      flex: 0 0 70%;
      max-width: 70%;
      overflow-wrap: anywhere;
    }
  }

  @media (min-width: 992px) {
    cdetails {
      flex: 0 0 30%;
      max-width: 30%;
    }
  }

  ul {
    display: flex;
    list-style-type: none;
    gap: 1rem;
    li {
      a {
        text-decoration: none;
        color: black;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #302ce9;
        }
      }
      svg {
        font-size: 1.3rem;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #302ce9;
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1024px) {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    ul {
      flex-direction: column;
    }
    .social__links {
      flex-direction: row;
    }
  }
`;
