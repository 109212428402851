import React from "react";
import noImage2 from "../assets/noImage2.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const CarouselImages = ({ images }) => {
  return (
    <Carousel
      showThumbs={false}
      infiniteLoop={true}
      autoPlay={true}
      interval={3000}
    >
      {images ? (
        images.map((image, index) => {
          return (
            <div key={index}>
              <img src={image?.bannerPath} alt={`Slide ${index}`} />
              {/* <p className="legend">{image.title}</p> */}
            </div>
          );
        })
      ) : (
        <img
          src={noImage2} // Initial source
          alt={"No data available"}
          onError={(e) => {
            e.target.onerror = null; // Prevent infinite loop in case `noImage` also fails
            e.target.src = noImage2; // Fallback image if the original fails
          }}
          style={{
            maxWidth: "100%",
            height: "350px",
            objectFit: "fill",
            borderRadius: "1rem",
          }}
        />
      )}
    </Carousel>
  );
};

export default CarouselImages;
