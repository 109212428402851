import React, { useState, useEffect } from "react";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { camelCase } from "../Utils/Utils";
import LoadingOverlay from "react-loading-overlay";
import { CircularProgress } from "@mui/material";
import { Button } from "react-bootstrap";
import noDataFound2 from "../assets/noDataFound2.png";


const BookingDetails = () => {
  const [bookingDetails, setBookingDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getBookingDetails();
  }, []);

  const getBookingDetails = () => {
    setIsLoading(true);
    var userData = JSON.parse(localStorage.getItem("userLoginDetails"));
    const body = { userId: userData?.uuid };
    moduleApi.postData(apiCall.getPastBookings, body).then((response) => {
      if (response?.status === 200) {
        setBookingDetails(response.data);
        setIsLoading(false);
      }
    });
  };

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        // spinner
        text={
          <p
            style={{
              color: "black",
            }}
          >
            <div>
              <Button
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "25px",
                  backgroundColor: "#FFFF",
                  color: "black",
                  borderColor: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                variant="primary"
                disabled
              >
                {/* <Spinner
                      as="span"
                      animation="border"
                      size="md"
                      variant="primary"
                      role="status"
                      aria-hidden="true"
                    /> */}
                <CircularProgress />
                <span>&nbsp;&nbsp;Please Wait...</span>
              </Button>
            </div>
          </p>
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(192,192,192,0.4)",
          }),
          spinner: (base) => ({
            ...base,
            width: "80px",
            "& svg circle": {
              stroke: "rgb(136, 108, 192)",
            },
          }),
        }}
      >
        <div style={{ minHeight: "80vh" }}>
          <p style={styles.sectionTitle}>Your Trips</p>
          {bookingDetails?.length > 0 ? (
            <div style={styles.cardsContainer}>
              {bookingDetails.map((booking, index) => (
                <div key={index} style={{ ...styles.card }} className="card">
                  <strong style={styles.bookingTitle}>
                    {booking?.subPackageDetails?.packageInfo?.packTitle
                      ? booking?.subPackageDetails?.packageInfo?.packTitle
                      : "-"}
                  </strong>
                  <strong style={styles.bookingTitle}>
                    {booking.pickupPointDetails?.name
                      ? booking.pickupPointDetails?.name
                      : "-"}{" "}
                    -{" "}
                    {booking.droppingPointDetails?.name
                      ? booking.droppingPointDetails?.name
                      : "-"}
                  </strong>

                  <div style={styles.container}>
                    <div style={styles.section}>
                      <p style={styles.text}>
                        Booking ID:
                        <strong>
                          {" "}
                          {booking.bookingID ? booking.bookingID : "-"}
                        </strong>
                      </p>
                      <p style={styles.text}>
                        Name:
                        <strong> {booking.name ? booking.name : "-"}</strong>
                      </p>
                      <p style={styles.text}>
                        Phone:
                        <strong>
                          {" "}
                          {booking.phoneNumber ? booking.phoneNumber : "-"}
                        </strong>
                      </p>
                      {/* <p style={styles.text}>
                  Title:<strong> {booking?.subPackageDetails?.packageInfo?.packTitle?booking?.subPackageDetails?.packageInfo?.packTitle:'-'}</strong>
                </p> */}
                      <p style={styles.text}>
                        Email:
                        <strong> {booking.mail ? booking.mail : "-"}</strong>
                      </p>
                    </div>
                    <div style={styles.section}>
                      <p style={styles.text}>
                        Adults:
                        <strong>
                          {" "}
                          {booking.noOfAdults ? booking.noOfAdults : "-"}
                        </strong>
                      </p>
                      <p style={styles.text}>
                        Children:
                        <strong>
                          {" "}
                          {booking.noOfChilds ? booking.noOfChilds : "-"}
                        </strong>
                      </p>
                      <p style={styles.text}>
                        Total Fare:
                        <strong>
                          {" "}
                          {booking.paymentDetails?.paymentAmount
                            ? booking.paymentDetails?.paymentAmount
                            : "-"}
                        </strong>
                      </p>
                      {/* <p style={styles.text}>
                  Package Name:<strong> {booking.package?.subtitle?booking.package?.subtitle:'-'}</strong>
                </p>
                <p style={styles.text}>
                  Sub package Name:
                  <strong> {booking.subPackageDetails?.sub_subtitle?booking.subPackageDetails?.sub_subtitle:'-'}</strong>
                </p> */}
                      <p style={styles.text}>
                        Status:{" "}
                        <strong>
                          {" "}
                          <span
                            style={{
                              color:
                                booking?.tripStatus?.toLowerCase() ===
                                "completed"
                                  ? "green"
                                  : booking?.tripStatus?.toLowerCase() ===
                                      "payment_failure" ||
                                    booking?.tripStatus?.toLowerCase() ===
                                      "cancelled"
                                  ? "red"
                                  : booking?.tripStatus?.toLowerCase() ===
                                    "upcoming"
                                  ? "orange"
                                  : "blue",
                            }}
                          >
                            {booking.tripStatus
                              ? camelCase(booking.tripStatus)
                              : "-"}
                          </span>
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <img
                src={noDataFound2}
                alt="No data found"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          )}
        </div>

        <div style={{ flex: 1 }}></div>
      </LoadingOverlay>
    </div>
  );
};

const styles = {
  title: {
    display: "flex",
    gap: "20px",
    flexDirection: "column",
  },
  cardsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
  },
  card: {
    padding: "25px",
    marginBottom: "10px",
    borderWidth: "1px",
    borderRadius: "10px",
    boxShadow: "1px 1px 10px #aaa",
    width: "100%",
    maxWidth: "900px",
    fontFamily: "'Arial', sans-serif",
    color: "#333",
    border: "1px solid #ddd",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  section: {
    flex: 1,
    padding: "10px",
  },
  sectionTitle: {
    fontSize: "25px",
    marginBottom: "10px",
    color: "#3f51b5",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  bookingTitle: {
    fontSize: "22px",
    marginBottom: "10px",
    color: "green",
    display: "flex",
    justifyContent: "center",
  },
  text: {
    marginBottom: "8px",
  },
};

export default BookingDetails;
