import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import "./Login.css";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { toaster } from "../rest/apiUtils";
import swal from "sweetalert";


const Login = ({updateAuthentication, updateSignUp, mobileNumber}) => {
  // const emptyArr = ["", "", "", ""];
  // const refs = [useRef(), useRef(), useRef(), useRef()];
  // const [inputs, setInputs] = useState(emptyArr);
  // // eslint-disable-next-line
  // const [missing, setMissing] = useState(emptyArr);
  const [mobileNo, setMobileNo] = useState(mobileNumber?mobileNumber:"");
  const [enteredOtp, setEnteredOtp] = useState("");
  const [isOtpVerification, setIsOtpVerification] = useState(false);
  // const CODE = "1234";

  // const handleSubmit = () => {
  //   const missed = inputs
  //     // eslint-disable-next-line
  //     .map((item, i) => {
  //       if (item === "") return i;
  //     })
  //     .filter((item) => item || item === 0);
  //   console.log("missed ", missed);
  //   setMissing(missed);
  //   if (missed.length) {
  //     return;
  //   }

  //   const userInput = inputs.join("");
  //   const isMatch = userInput === CODE;
  //   const msg = isMatch ? "Code is Valid" : "Code is not Valid";
  //   alert(msg);
  // };
  // useEffect(() => {
  //   isOtpVerification && refs[0].current.focus();
  //   // eslint-disable-next-line
  // }, []);

  // const handleInputChange = (e, index) => {
  //   const val = e.target.value;
  //   console.log(val, index);
  //   if (!Number(val)) return;

  //   if (index < inputs.length - 1) {
  //     // 1
  //     refs[index + 1].current.focus();
  //   }
  //   const copyInputs = [...inputs];
  //   copyInputs[index] = val;
  //   // console.log(inputs);
  //   setInputs(copyInputs);
  // };

  // const handleOnKeyDown = (e, index) => {
  //   console.log(e.keyCode, index);
  //   if (e.keyCode === 8) {
  //     const copyInputs = [...inputs];
  //     copyInputs[index] = "";
  //     setInputs(copyInputs);

  //     if (index > 0) {
  //       refs[index - 1].current.focus();
  //     }
  //   }
  // };

  // const handlePaste = (e) => {
  //   const data = e.clipboardData.getData("text");
  //   // console.log("paste data ", data);
  //   if (!Number(data) || data.length !== inputs.length) return;

  //   const pastCode = data.split("");
  //   setInputs(pastCode);
  //   refs[inputs.length - 1].current.focus();
  // };

  const getOtpFromMobileNo = () => {
    if (mobileNo?.length !== 10) {
      return toaster("error", "Please Enter Valid Mobile No");
    }
    var payload = {
      phoneNumber: mobileNo,
    };
    // let formData = new FormData();
    // formData.append("phoneNumber", mobileNo);
    // console.log(formData);

    moduleApi.postData(apiCall.getLoginOtp, payload).then((response) => {
      if (
        response?.message === "OTP sent to entered phone number, please verify"
      ) {
        setIsOtpVerification(true);
        return toaster("success", "OTP Sent Successfully");
      }
      else if(response?.message === "User not exist navigate to signup"){
        updateSignUp();
      }
    });
  };

  const getOtpVerification = () => {
    // let formData = new FormData();
    // formData.append("phoneNumber", mobileNo);
    // formData.append("otp", '0000');
    // console.log(formData);
    // const missed = inputs
    //   // eslint-disable-next-line
    //   .map((item, i) => {
    //     if (item === "") return i;
    //   })
    //   // .filter((item) => item || item === 0);
    // console.log("missed ", missed);
    // setMissing(missed);
    // if (missed.length) {
    //   return;
    // }
    // for (const value of inputs) {
    //   if (value === "") {
    //     return toaster("error", "Please Enter Otp");
    //   }
    // }

    var payload = {
      phoneNumber: mobileNo,
      otp: enteredOtp,
      // otp: inputs.join(""),
    };
    console.log(payload);
    moduleApi.postData(apiCall.verifyOtp, payload).then((response) => {
      if (response?.message === "Please enter valid OTP") {
        return toaster("error", "Please enter valid OTP");
      }
      else if(response?.data){
        updateAuthentication()
        localStorage.setItem('userLoginDetails', JSON.stringify(response?.data))
        return swal("Logged in Successfully!", {
          icon: "success",
        });
      }
      // else if()
      // if(response?.message==="OTP sent to entered phone number, please verify"){
      //   setIsOtpVerification(true)
      //   return toaster('success', "OTP Sent Successfully")
      // }
    });
  };
  return (
    <div>
      {!isOtpVerification && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            margin: "20px",
            padding: "15px",
            borderRadius: "12px",
            border: "1px solid #dae1e5",
          }}
        >
          {/* <label
          style={{
            fontSize: "medium",
            fontWeight: "400",
            marginBottom: "3px",
          }}
          className="control-label"
        >
          Mobile Number
        </label> */}

          <TextField
            label={`Mobile Number`}
            type="number"
            value={mobileNo}
            onChange={(e) => {
              setMobileNo(e.target.value);
            }}
            placeholder="Enter Mobile Number"
            // error={!!errors.childAges[index]}
            // helperText={errors.childAges[index]}
            margin="normal"
            fullWidth
          />
          <Button
            style={{
              backgroundColor: "#1976d2",
              padding: "6px 16px",
              borderRadius: "4px",
              boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
              marginLeft: "10px",
              marginTop: "10px",
              alignSelf: "center",
              color: "#fff",
            }}
            variant="contained"
            onClick={() =>
              // mobileNo?.length === 10 && setIsOtpVerification(true)
              getOtpFromMobileNo()
            }
          >
            Proceed
          </Button>
        </div>
      )}
      {isOtpVerification && (
        <div
          className="App"
          style={{
            margin: "20px",
            padding: "15px",
            borderRadius: "12px",
            border: "1px solid #dae1e5",
          }}
        >
          <h2>OTP Verification</h2>
          <div style={{ marginTop: "10px" }}>
          <TextField
            label={`OTP`}
            type="number"
            value={enteredOtp}
            onChange={(e) => {
              setEnteredOtp(e.target.value);
            }}
            placeholder="Enter Otp"
            // error={!!errors.childAges[index]}
            // helperText={errors.childAges[index]}
            margin="normal"
            fullWidth
          />
            {/* {emptyArr.map((item, i) => {
              return (
                <input
                  value={inputs[i]}
                  key={i}
                  ref={refs[i]}
                  type="text"
                  min={0}
                  max={9}
                  maxLength="1"
                  onPaste={handlePaste}
                  onChange={(e) => handleInputChange(e, i)}
                  onKeyDown={(e) => handleOnKeyDown(e, i)}
                  className={missing.includes(i) ? "error" : ""}
                />
              );
            })} */}
          </div>
          <Button
            style={{
              backgroundColor: "#1976d2",
              padding: "6px 16px",
              borderRadius: "4px",
              boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
              marginLeft: "10px",
              marginTop: "10px",
              color: "#fff",
            }}
            variant="contained"
            onClick={() => getOtpVerification()}
          >
            Submit
          </Button>
        </div>
      )}
    </div>
  );
};

export default Login;
