import React, { useState, useEffect } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  MenuItem,
  Select,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  FormHelperText,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
// import { toaster } from "../rest/apiUtils";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { makeStyles } from "@mui/styles";
import onlinePayment from "../assets/onlinePayment.jpg";
import { cashfree } from "./InitializeCashfree";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
// import Login from "./Login";
// import Signup from "./Signup";
import ClearIcon from "@mui/icons-material/Clear";
import { Modal } from "react-bootstrap";
import { toaster } from "../rest/apiUtils";
// import MuiTextField from "./MuiTextField";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 20,
    overflowX: "auto",
  },
  tableHeader: {
    fontWeight: "bold",
  },
}));

const userLoginDetails = JSON.parse(localStorage.getItem("userLoginDetails"));

const initialValues = {
  adults: "",
  children: "",
  date: "",
  boardingPoint: "",
  droppingPoint: "",
  userName: userLoginDetails ? userLoginDetails?.name : "",
  userGender: userLoginDetails ? userLoginDetails?.gender : "",
  userAge: userLoginDetails ? userLoginDetails?.age : "",
  mobileNo: userLoginDetails ? userLoginDetails?.phone : "",
  alternateMobileNo: "",
  emailId: userLoginDetails ? userLoginDetails?.email : "",
  names: [],
  genders: [],
  ages: [],
  childNames: [],
  childGenders: [],
  childAges: [],
  fullAmount: "",
  minAmount: "",
  selectedAmount: "",
};

const initialErrors = {
  adults: "",
  children: "",
  date: "",
  boardingPoint: "",
  droppingPoint: "",
  userName: "",
  userGender: "",
  userAge: "",
  mobileNo: "",
  alternateMobileNo: "",
  emailId: "",
  names: [],
  genders: [],
  ages: [],
  childNames: [],
  childGenders: [],
  childAges: [],
};

const steps = ["Basic Details", "Passenger Details", "Review Details"];

// let options = [
//   { label: 1, value: 1 },
//   { label: 2, value: 2 },
//   { label: 3, value: 3 },
//   { label: 4, value: 4 },
//   { label: 5, value: 5 },
//   { label: 6, value: 6 },
//   { label: 7, value: 7 },
//   { label: 8, value: 8 },
//   { label: 9, value: 9 },
//   { label: 10, value: 10 },
//   { label: 11, value: 11 },
//   { label: 12, value: 12 },
//   { label: 13, value: 13 },
//   { label: 14, value: 14 },
//   { label: 15, value: 15 },
//   { label: 16, value: 16 },
//   { label: 17, value: 17 },
//   { label: 18, value: 18 },
//   { label: 19, value: 19 },
//   { label: 20, value: 20 },
// ];
// let childrenOptions = [
//   { label: 0, value: 0 },
//   { label: 1, value: 1 },
//   { label: 2, value: 2 },
//   { label: 3, value: 3 },
//   { label: 4, value: 4 },
//   { label: 5, value: 5 },
//   { label: 6, value: 6 },
//   { label: 7, value: 7 },
//   { label: 8, value: 8 },
//   { label: 9, value: 9 },
//   { label: 10, value: 10 },
//   { label: 11, value: 11 },
//   { label: 12, value: 12 },
//   { label: 13, value: 13 },
//   { label: 14, value: 14 },
//   { label: 15, value: 15 },
//   { label: 16, value: 16 },
//   { label: 17, value: 17 },
//   { label: 18, value: 18 },
//   { label: 19, value: 19 },
//   { label: 20, value: 20 },
// ];

const Step3Table = ({ values }) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState(values.minAmount); // default value can be minAmount or fullAmount

  const paymentOptions = [
    {
      label: `Minimum Payment: Rs ${values.minAmount}`,
      value: values.minAmount,
    },
    {
      label: `Full Payment: Rs ${values.fullAmount}`,
      value: values.fullAmount,
    },
  ];

  const handleChange = (event) => {
    setSelectedValue(event.target.value); // Update the selected value
    values.selectedAmount = event.target.value;
    console.log(values);
  };

  const totalAdults = parseInt(values.adults || 0, 10);
  const totalChildren = parseInt(values.children || 0, 10);

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <div style={{ width: "100%", overflowX: "auto" }}>
        <Table className={classes.table} aria-label="review table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>Field</TableCell>
              <TableCell className={classes.tableHeader}>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>{values.date}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Number of Adults</TableCell>
              <TableCell>{values.adults}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Number of Children</TableCell>
              <TableCell>{values.children ? values?.children : 0}</TableCell>
            </TableRow>
            <Table className={classes.table} aria-label="review table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader}>Name</TableCell>
                  <TableCell className={classes.tableHeader}>Gender</TableCell>
                  <TableCell className={classes.tableHeader}>Age</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from({ length: totalAdults }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>{values.names[index] || "N/A"}</TableCell>
                    <TableCell>{values.genders[index] || "N/A"}</TableCell>
                    <TableCell>{values.ages[index] || "N/A"}</TableCell>
                  </TableRow>
                ))}
                {Array.from({ length: totalChildren }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>{values.childNames[index] || "N/A"}</TableCell>
                    <TableCell>{values.childGenders[index] || "N/A"}</TableCell>
                    <TableCell>{values.childAges[index] || "N/A"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableBody>
        </Table>
        <br />
        <br />
        <FormControl style={{ marginLeft: "15px", marginBottom: "15px" }}>
          <FormLabel id="payment-radio-buttons-group">
            Select Payment Option
          </FormLabel>
          <RadioGroup
            aria-labelledby="payment-radio-buttons-group"
            name="payment-radio-buttons-group"
            value={selectedValue}
            onChange={handleChange}
          >
            {paymentOptions.map((option) => (
              <div key={option.value}>
                <FormControlLabel
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
                {option.value === values.minAmount && (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ marginLeft: "32px" }}
                  >
                    Pay 10% now and confirm the trip. The rest will be paid
                    during the trip.
                  </Typography>
                )}
              </div>
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    </TableContainer>
  );
};

function getStepContent(
  step,
  values,
  handleChange,
  errors,
  dropdownOpen,
  handleDropdownOpen,
  handleDropdownClose,
  boardingPoints,
  droppingPoints,
  packageInfo,
  getBoardingPointLabelUi
) {
  switch (step) {
    case 0:
      return (
        <>
          <TextField
            fullWidth
            margin="normal"
            label="Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={values.date}
            inputProps={{
              // only needs the first 16 characters in the date string
              min: new Date(
                new Date().setDate(new Date().getDate() + 1)
              ).toLocaleDateString("en-CA"),
            }}
            onChange={handleChange("date")}
            error={!!errors.date}
            helperText={errors.date}
          />
          <FormControl
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.adults}
          >
            <InputLabel>No of Adults</InputLabel>
            <Select
              label="No of Adults"
              value={values.adults}
              onChange={handleChange("adults")}
              open={dropdownOpen.adults}
              onClose={handleDropdownClose("adults")}
              onOpen={handleDropdownOpen("adults")}
              fullWidth
            >
              {packageInfo?.noOfPassengers?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            {errors.adults && <FormHelperText>{errors.adults}</FormHelperText>}
            <FormHelperText style={{ color: "red" }}>
              {"Note: Minimum Passengers allowed: " +
                packageInfo?.minNoOfPassengers}
            </FormHelperText>
          </FormControl>
          <FormControl
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.children}
          >
            <InputLabel>No of Children</InputLabel>
            <Select
              label="No of Children"
              value={values.children}
              onChange={handleChange("children")}
              open={dropdownOpen.children}
              onClose={handleDropdownClose("children")}
              onOpen={handleDropdownOpen("children")}
              fullWidth
            >
              {packageInfo?.noOfChilds?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            {errors.children && (
              <FormHelperText>{errors.children}</FormHelperText>
            )}
          </FormControl>

          {/* <FormControl fullWidth margin="normal" error={!!errors.children}>
            <InputLabel>No of Children</InputLabel>
            <Select
              value={values.children}
              onChange={handleChange("children")}
              open={dropdownOpen.children}
              onClose={handleDropdownClose("children")}
              onOpen={handleDropdownOpen("children")}
              fullWidth
            >
              {[...Array(31).keys()].map((number) => (
                <MenuItem key={number} value={number}>
                  {number}
                </MenuItem>
              ))}
            </Select>
            {errors.children && (
              <FormHelperText>{errors.children}</FormHelperText>
            )}
          </FormControl> */}
          <FormControl
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.boardingPoint}
          >
            <InputLabel>Boarding Point</InputLabel>
            <Select
              label="Boarding Point"
              value={values.boardingPoint}
              onChange={handleChange("boardingPoint")}
              // sx = {{
              //   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              //     borderColor: 'green'
              //   }
              // }}
              // open={dropdownOpen.boardingPoint}
              // onClose={handleDropdownClose("boardingPoint")}
              // onOpen={handleDropdownOpen("boardingPoint")}
              fullWidth
            >
              {boardingPoints.map((option, index) => (
                // <MenuItem key={option.value} value={option.value}>
                //   {getBoardingPointLabelUi(option)}
                // </MenuItem>
                <MenuItem
                  // onClick={() => {
                  //   setValue(key);
                  // }}
                  style={{ display: "block" }}
                  key={index}
                  value={option.value}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      alignItems: "center",
                      columnGap: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <span style={{ fontSize: "medium", fontWeight: "bold" }}>
                        {option?.name}
                      </span>
                      <span style={{ fontSize: "small" }}>
                        {option?.description}
                      </span>
                      <span>{option?.route}</span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <span style={{ fontSize: "large", fontWeight: "bolder" }}>
                        {option?.time}
                      </span>
                    </div>
                  </div>
                  {/* <hr></hr> */}
                </MenuItem>
              ))}
            </Select>
            {errors.boardingPoint && (
              <FormHelperText>{errors.boardingPoint}</FormHelperText>
            )}
            <FormHelperText style={{ color: "red" }}>
              {"Note: Boarding and Dropping point are same"}
            </FormHelperText>
          </FormControl>
          <FormControl
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.droppingPoint}
          >
            <InputLabel>Dropping Point</InputLabel>
            <Select
              label="Dropping Point"
              value={
                packageInfo?.isPickDropSame === 1
                  ? values?.boardingPoint
                  : values.droppingPoint
              }
              onChange={handleChange("droppingPoint")}
              // open={dropdownOpen.droppingPoint}
              // onClose={handleDropdownClose("droppingPoint")}
              // onOpen={handleDropdownOpen("droppingPoint")}
              // disabled={packageInfo?.isPickDropSame === 1 ? true : false}
              fullWidth
            >
              {droppingPoints.map((option, index) => (
                <MenuItem
                  // onClick={() => {
                  //   setValue(key);
                  // }}
                  style={{ display: "block" }}
                  key={index}
                  value={option.value}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      alignItems: "center",
                      columnGap: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <span style={{ fontSize: "medium", fontWeight: "bold" }}>
                        {option?.name}
                      </span>
                      <span style={{ fontSize: "small" }}>
                        {option?.description}
                      </span>
                      <span>{option?.route}</span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <span style={{ fontSize: "large", fontWeight: "bolder" }}>
                        {option?.time}
                      </span>
                    </div>
                  </div>
                  {/* <hr></hr> */}
                </MenuItem>
              ))}
            </Select>
            {errors.droppingPoint && (
              <FormHelperText>{errors.droppingPoint}</FormHelperText>
            )}
          </FormControl>
          {/* <FormControl fullWidth margin="normal" error={!!errors.droppingPoint}>
          <TextField
            select
            label="Droppping Point"
            // name="droppingPoint"
            value={values.droppingPoint}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          
          {errors.droppingPoint && (
            <FormHelperText>{errors.droppingPoint}</FormHelperText>
          )}
          </FormControl> */}
        </>
      );
    case 1:
      return (
        <>
          <div
            //   className="card"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "15px",
              padding: "12px",
              borderWidth: "1px",
              borderRadius: "20px",
              boxShadow: "1px 1px 5px #797979",
            }}
          >
            <h3 style={{ marginBottom: "5px" }}>Contact Details</h3>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
                columnGap: "15px",
                padding: "12px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Full Name"
                    placeholder="Enter Full Name"
                    type="text"
                    value={values.userName}
                    onChange={handleChange("userName")}
                    error={!!errors.userName}
                    helperText={errors.userName}
                  />
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                    fullWidth
                    margin="normal"
                    label="Gender"
                    placeholder="Enter Gender"
                    type="text"
                    value={values.userGender}
                    onChange={handleChange("userGender")}
                    error={!!errors.userGender}
                    helperText={errors.userGender}
                  /> */}
                  <FormControl
                    fullWidth
                    margin="normal"
                    error={!!errors.userGender}
                  >
                    <InputLabel>Gender</InputLabel>
                    <Select
                      label={`Gender`}
                      value={values.userGender}
                      onChange={handleChange("userGender")}
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                    {errors.userGender && (
                      <FormHelperText>{errors.userGender}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Age"
                    placeholder="Enter Age"
                    type="number"
                    value={values.userAge}
                    onChange={handleChange("userAge")}
                    error={!!errors.userAge}
                    helperText={errors.userAge}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Mobile No"
                    placeholder="Enter Mobile No"
                    type="number"
                    value={values.mobileNo}
                    onChange={handleChange("mobileNo")}
                    error={!!errors.mobileNo}
                    helperText={errors.mobileNo}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Alternate Mobile No"
                    placeholder="Enter Alternate Mobile No"
                    type="number"
                    value={values.alternateMobileNo}
                    onChange={handleChange("alternateMobileNo")}
                    error={!!errors.alternateMobileNo}
                    helperText={errors.alternateMobileNo}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Email Id"
                    placeholder="Enter Email Id"
                    type="text"
                    value={values.emailId}
                    onChange={handleChange("emailId")}
                    error={!!errors.emailId}
                    helperText={errors.emailId}
                  />
                </Grid>
              </Grid>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignSelf: "flex-start",
                marginLeft: "5px",
                alignItems: "center",
                color: "#0faa14",
              }}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "2px",
                }}
              >
                {" "}
                <CheckCircleIcon style={{ fontSize: "medium" }} />
                &nbsp; Your booking details will be sent to above mobile number
                and email id.
              </span>
            </div>
          </div>

          {values.adults !== "0" && values.adults !== "" && (
            <h3 style={{ margin: "10px" }}>Passenger Details</h3>
          )}

          {Array.from({
            length: parseInt(values.adults || 0, 10),
          }).map((_, index) => (
            <>
              <div
                style={{
                  position: "relative",
                  top: "-3px",
                  left: "10px",
                  marginTop: "20px",
                }}
              >
                <span
                  style={{
                    alignSelf: "flex-start",
                    backgroundColor: "#0faa14",
                    color: "#fff",
                    padding: "2px 10px",
                    marginRight: "5px",
                    verticalAlign: "text-top",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "10px",
                    boxShadow: "1px 1px 5px #797979",
                    fontWeight: "500",
                    // boxShadow:'0 2px 2px 0 rgba(0,0,0,0.26)'
                  }}
                >
                  {"Adult " + (index + 1)}
                </span>
              </div>
              <div
                // className="card"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  columnGap: "15px",
                  padding: "12px",
                  borderWidth: "1px",
                  borderRadius: "20px",
                  boxShadow: "1px 1px 5px #797979",
                }}
              >
                <Grid container spacing={2} key={index}>
                  <Grid item xs={4}>
                    <TextField
                      label={`Name`}
                      value={values.names[index] || ""}
                      onChange={handleChange("names", index)}
                      placeholder="Enter Name"
                      error={!!errors.names[index]}
                      helperText={errors.names[index]}
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    {/* <TextField
                      label={`Gender`}
                      value={values.genders[index] || ""}
                      onChange={handleChange("genders", index)}
                      placeholder="Enter Gender"
                      error={!!errors.genders[index]}
                      helperText={errors.genders[index]}
                      margin="normal"
                      fullWidth
                    /> */}
                    <FormControl
                      fullWidth
                      margin="normal"
                      error={!!errors.genders[index]}
                    >
                      <InputLabel>Gender</InputLabel>
                      <Select
                        label={`Gender`}
                        value={values.genders[index] || ""}
                        onChange={handleChange("genders", index)}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                      {errors.genders[index] && (
                        <FormHelperText>{errors.genders[index]}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={`Age`}
                      type="number"
                      value={values.ages[index] || ""}
                      onChange={handleChange("ages", index)}
                      placeholder="Enter Age"
                      error={!!errors.ages[index]}
                      helperText={errors.ages[index]}
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </div>
            </>
          ))}

          {Array.from({
            length: parseInt(values.children || 0, 10),
          }).map((_, index) => (
            <>
              <div
                style={{
                  position: "relative",
                  top: "-3px",
                  left: "10px",
                  marginTop: "20px",
                }}
              >
                <span
                  style={{
                    alignSelf: "flex-start",
                    backgroundColor: "#0faa14",
                    color: "#fff",
                    padding: "2px 10px",
                    marginRight: "5px",
                    verticalAlign: "text-top",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "10px",
                    boxShadow: "1px 1px 5px #797979",
                    fontWeight: "500",
                    // boxShadow:'0 2px 2px 0 rgba(0,0,0,0.26)'
                  }}
                >
                  {"Child " + (index + 1)}
                </span>
              </div>
              <div
                // className="card"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  columnGap: "15px",
                  padding: "12px",
                  borderWidth: "1px",
                  borderRadius: "20px",
                  boxShadow: "1px 1px 5px #797979",
                }}
              >
                <Grid container spacing={2} key={index}>
                  <Grid item xs={4}>
                    <TextField
                      label={`Name`}
                      value={values.childNames[index] || ""}
                      onChange={handleChange("childNames", index)}
                      placeholder="Enter Name"
                      error={!!errors.childNames[index]}
                      helperText={errors.childNames[index]}
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    {/* <TextField
                      label={`Gender`}
                      value={values.childGenders[index] || ""}
                      onChange={handleChange("childGenders", index)}
                      placeholder="Enter Gender"
                      error={!!errors.childGenders[index]}
                      helperText={errors.childGenders[index]}
                      margin="normal"
                      fullWidth
                    /> */}
                    <FormControl
                      fullWidth
                      margin="normal"
                      error={!!errors.childGenders[index]}
                    >
                      <InputLabel>Gender</InputLabel>
                      <Select
                        label={`Gender`}
                        value={values.childGenders[index] || ""}
                        onChange={handleChange("childGenders", index)}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                      {errors.childGenders[index] && (
                        <FormHelperText>
                          {errors.childGenders[index]}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={`Child Age`}
                      type="number"
                      value={values.childAges[index] || ""}
                      onChange={handleChange("childAges", index)}
                      placeholder="Enter Child Age"
                      error={!!errors.childAges[index]}
                      helperText={errors.childAges[index]}
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </div>
            </>
          ))}
        </>
      );
    case 2:
      return <Step3Table values={values} />;
    // case 2:
    //   return (
    //     <TableContainer component={Paper} className={useStyles().tableContainer}>
    //       <Table className={useStyles().table} aria-label="review table">
    //         <TableHead>
    //           <TableRow>
    //             <TableCell className={useStyles().tableHeader}>Field</TableCell>
    //             <TableCell className={useStyles().tableHeader}>Details</TableCell>
    //           </TableRow>
    //         </TableHead>
    //         <TableBody>
    //           <TableRow>
    //             <TableCell>Date</TableCell>
    //             <TableCell>{values.date}</TableCell>
    //           </TableRow>
    //           <TableRow>
    //             <TableCell>Number of Adults</TableCell>
    //             <TableCell>{values.adults}</TableCell>
    //           </TableRow>
    //           <TableRow>
    //             <TableCell>Number of Children</TableCell>
    //             <TableCell>{values.children}</TableCell>
    //           </TableRow>
    //           {Array.from({ length: parseInt(values.adults || 0, 10) + parseInt(values.children || 0, 10) }).map((_, index) => (
    //             <React.Fragment key={index}>
    //               <TableRow>
    //                 <TableCell>{`Person ${index + 1} Name`}</TableCell>
    //                 <TableCell>{values.names[index] || 'N/A'}</TableCell>
    //               </TableRow>
    //               <TableRow>
    //                 <TableCell>{`Person ${index + 1} Gender`}</TableCell>
    //                 <TableCell>{values.genders[index] || 'N/A'}</TableCell>
    //               </TableRow>
    //               <TableRow>
    //                 <TableCell>{`Person ${index + 1} Age`}</TableCell>
    //                 <TableCell>{values.ages[index] || 'N/A'}</TableCell>
    //               </TableRow>
    //             </React.Fragment>
    //           ))}
    //         </TableBody>
    //       </Table>
    //     </TableContainer>
    //     // <div>
    //     //   <Typography variant="h6" gutterBottom>
    //     //     Review Details
    //     //   </Typography>
    //     //   <Typography variant="body1">
    //     //     <strong>Date:</strong> {values.date}
    //     //   </Typography>
    //     //   <Typography variant="body1">
    //     //     <strong>No of Adults:</strong> {values.adults}
    //     //   </Typography>
    //     //   <Typography variant="body1">
    //     //     <strong>No of Children:</strong> {values.children}
    //     //   </Typography>
    //     //   <Typography variant="body1">
    //     //     <strong>Boarding Point:</strong> {values.boardingPoint}
    //     //   </Typography>
    //     //   <Typography variant="body1">
    //     //     <strong>Dropping Point:</strong> {values.droppingPoint}
    //     //   </Typography>
    //     //   {Array.from({
    //     //     length: parseInt(values.adults || 0, 10),
    //     //   }).map((_, index) => (
    //     //     <div key={index}>
    //     //       <Typography variant="body1">
    //     //         <strong>Adult {index + 1}: Details</strong>
    //     //       </Typography>
    //     //       <Typography variant="body1">
    //     //         Name: {values.names[index] || "-"}
    //     //       </Typography>
    //     //       <Typography variant="body1">
    //     //         Gender: {values.genders[index] || "-"}
    //     //       </Typography>
    //     //       <Typography variant="body1">
    //     //         Age: {values.ages[index] || "-"}
    //     //       </Typography>
    //     //     </div>
    //     //   ))}
    //     //   {Array.from({
    //     //     length: parseInt(values.children || 0, 10),
    //     //   }).map((_, index) => (
    //     //     <div key={index}>
    //     //       <Typography variant="body1">
    //     //         <strong>Child {index + 1}: Details</strong>
    //     //       </Typography>
    //     //       <Typography variant="body1">
    //     //         Name: {values.childNames[index] || "-"}
    //     //       </Typography>
    //     //       <Typography variant="body1">
    //     //         Gender: {values.childGenders[index] || "-"}
    //     //       </Typography>
    //     //       <Typography variant="body1">
    //     //         Age: {values.childAges[index] || "-"}
    //     //       </Typography>
    //     //     </div>
    //     //   ))}
    //     // </div>
    //   );
    default:
      return "Unknown step";
  }
}

const validateStep = (step, values) => {
  let errors = { ...initialErrors };
  // console.log(step);

  if (step === 0) {
    // console.log("step0", values);

    if (!values.adults) {
      errors.adults = "Number of adults is required";
    }
    if (values.children < 0) {
      errors.children = "Number of children cannot be negative";
    }
    if (!values.date) {
      errors.date = "Date is required";
    }
    if (!values.boardingPoint) {
      errors.boardingPoint = "Boarding Point is required";
    }
    if (!values.droppingPoint) {
      errors.droppingPoint = "Dropping Point is required";
    }
  }

  if (step === 1) {
    // console.log(values);
    if (!values.userName) {
      errors.userName = "Full Name is required";
    } else {
      errors.userName = "";
    }
    if (!values.userGender) {
      errors.userGender = "Gender is required";
    } else {
      errors.userGender = "";
    }
    if (!values.userAge) {
      errors.userAge = "Age is required";
    } else {
      errors.userAge = "";
    }
    if (!values.mobileNo) {
      errors.mobileNo = "Mobile No is required";
    } else if (values.mobileNo && !/^\d{10}$/.test(values.mobileNo)) {
      errors.mobileNo = "Enter a Valid 10-Digit Mobile No";
    } else {
      errors.mobileNo = "";
    }
    if (!values.alternateMobileNo) {
      errors.alternateMobileNo = "Alternate Mobile No is required";
    } else if (
      values.alternateMobileNo &&
      !/^\d{10}$/.test(values.alternateMobileNo)
    ) {
      errors.alternateMobileNo = "Enter a Valid 10-Digit Alternate Mobile No";
    } else {
      errors.alternateMobileNo = "";
    }
    if (!values.emailId) {
      errors.emailId = "Email Id is required";
      // } else if (!/^[^\s@]+@gmail\.com$/.test(values.email)) {
    } else if (!/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/.test(values.emailId)) {
      errors.emailId = "Enter a Valid Email Id";
    } else {
      errors.emailId = "";
    }
    for (let i = 0; i < parseInt(values.adults, 10); i++) {
      // console.log("stephh1", !values.names[i]);
      if (!values.names[i]) {
        errors.names[i] = "Name is required";
      } else {
        errors.names.splice(i, 1);
      }
      if (!values.genders[i]) {
        errors.genders[i] = "Gender is required";
      } else {
        errors.genders.splice(i, 1);
      }
      if (!values.ages[i] || values.ages[i] <= 0) {
        errors.ages[i] = "Age must be greater than 0";
      } else {
        errors.ages.splice(i, 1);
      }
    }
    for (
      let i = 0;
      i < parseInt(values.children !== "" ? values.children : 0, 10);
      i++
    ) {
      // console.log("stephhc1", !values.childNames[i]);
      if (!values.childNames[i]) {
        errors.childNames[i] = "Name is required";
      } else {
        errors.childNames.splice(i, 1);
      }
      if (!values.childGenders[i]) {
        errors.childGenders[i] = "Gender is required";
      } else {
        errors.childGenders.splice(i, 1);
      }
      if (!values.childAges[i] || values.childAges[i] <= 0) {
        errors.childAges[i] = "Child Age must be greater than 0";
      } else {
        errors.childAges.splice(i, 1);
      }
    }
  }
  console.log("step0 Errors", step, "step", errors);
  console.log("values", values);
  return errors;
};

const NewForm = (props) => {
  const { isAuthenticated, authenticate } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [loginModal, setLoginModal] = useState(false);
  const [enteredOtp, setEnteredOtp] = useState("");
  // const [signupModal, setSignupModal] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [tempIdForUserVerification, setTempIdForUserVerification] =
    useState(null);
  const [errors, setErrors] = useState(initialErrors);
  const [boardingPoints, setBoardingPoints] = useState([]);
  const [droppingPoints, setDroppingPoints] = useState([]);

  const [dropdownOpen, setDropdownOpen] = useState({
    adults: false,
    children: false,
    boardingPoint: false,
    droppingPoint: false,
  });

  let navigate = useNavigate();
  const { loader } = props;
  var packageInfo = JSON.parse(localStorage.getItem("packageInfo"));

  useEffect(() => {
    getBoardingPoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   const classes = useStyles();

  const calculateFare = () => {
    var plans = JSON.parse(localStorage.getItem("planDetails"));

    var adultsCount = values.names.length;
    var childCount = values.childNames.length;

    let adultfare =
      plans && plans.length > 0 ? plans[0].planAmount * adultsCount : 0;

    let childFare =
      plans && plans.length > 0 ? plans[1].planAmount * childCount : 0;

    setValues({
      ...values,
      fullAmount: adultfare + childFare,
      minAmount: ((adultfare + childFare) * 0.1).toFixed(2),
      selectedAmount: ((adultfare + childFare) * 0.1).toFixed(2),
    });
  };

  console.log(values);

  const getBoardingPoints = async () => {
    var subPackageId = localStorage.getItem("subPackageId");

    var payload = {
      subPackageId: subPackageId,
    };
    console.log(payload);
    const response = await moduleApi.postData(
      apiCall.getBoardingPoints,
      payload
    );
    const result = response?.data?.map((item) => ({
      // label: getBoardingPointLabelUi(item),
      description: item.description,
      name: item.name,
      route: item.route,
      time: item.time,
      value: item.pointId,
    }));
    const response2 = await moduleApi.postData(
      apiCall.getDroppingPoints,
      payload
    );
    const result2 = response2?.data?.map((item) => ({
      // label: getBoardingPointLabelUi(item),
      description: item.description,
      name: item.name,
      route: item.route,
      time: item.time,
      value: item.pointId,
    }));
    setBoardingPoints(result);
    setDroppingPoints(result2);
  };

  const getBoardingPointLabelUi = (data) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
          columnGap: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <span style={{ fontSize: "medium", fontWeight: "bold" }}>
            {data?.name}
          </span>
          <span style={{ fontSize: "small" }}>{data?.description}</span>
          <span>{data?.route}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <span style={{ fontSize: "large", fontWeight: "bolder" }}>
            {data?.time}
          </span>
        </div>
      </div>
    );
  };

  const getPassengerObject = (field, index, event, type) => {
    if (type === "Adult") {
      setValues((prev) => {
        const passengerDetails = [...prev.passengerDetails];
        passengerDetails[index] = {
          ...passengerDetails[index],
          [field]: event.target.value,
          ageType: type,
        };
        return { ...prev, passengerDetails };
      });
    } else {
      setValues((prev) => {
        const passengerDetails2 = [...prev.passengerDetails2];
        passengerDetails2[index] = {
          ...passengerDetails2[index],
          [field]: event.target.value,
          ageType: type,
        };
        return { ...prev, passengerDetails2 };
      });
    }
  };

  const handleChange = (field, index) => (event) => {
    if (index !== undefined) {
      setValues((prev) => {
        const newValue = [...prev[field]];
        newValue[index] = event.target.value;
        return { ...prev, [field]: newValue };
      });
      switch (field) {
        case "names":
          getPassengerObject("name", index, event, "Adult");
          break;
        case "genders":
          getPassengerObject("gender", index, event, "Adult");
          break;
        case "ages":
          getPassengerObject("age", index, event, "Adult");
          break;
        case "childNames":
          getPassengerObject("name", index, event, "Child");
          break;
        case "childGenders":
          getPassengerObject("gender", index, event, "Child");
          break;
        case "childAges":
          getPassengerObject("age", index, event, "Child");
          break;
        default:
        // code block
      }
      // if (field === "names") {
      //   getPassengerObject('name', index, event, "Adult")
      //   // setValues((prev) => {
      //   //   const passengerDetails = [...prev.passengerDetails];
      //   //   passengerDetails[index] = {
      //   //     ...passengerDetails[index],
      //   //     name: event.target.value,
      //   //   };
      //   //   return { ...prev, passengerDetails };
      //   // });
      // } else if (field === "ages") {

      // } else {
      //   setValues((prev) => {
      //     const passengerDetails = [...prev.passengerDetails];
      //     passengerDetails[index] = {
      //       ...passengerDetails[index],
      //       gender: event.target.value,
      //     };
      //     return { ...prev, passengerDetails };
      //   });
      // }
      setErrors((prevErrors) => {
        const newError = [...prevErrors[field]];
        newError[index] = "";
        return { ...prevErrors, [field]: newError };
      });
    } else {
      setValues((prev) => ({ ...prev, [field]: event.target.value }));
      setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
    }
  };

  const handleNext = () => {
    const validationErrors = validateStep(activeStep, values);
    if (activeStep === 0) {
      validationErrors.names = [];
      validationErrors.ages = [];
      validationErrors.genders = [];
      validationErrors.childNames = [];
      validationErrors.childGenders = [];
      validationErrors.childAges = [];
    }
    setErrors(validationErrors);

    const hasErrors = Object.values(validationErrors).some((error) =>
      Array.isArray(error) ? error.some(Boolean) : Boolean(error)
    );

    if (!hasErrors) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    console.log("values.adults:  ", values.adults);
    if (values.adults > 0) {
      calculateFare();
    }
  };

  const onSubmit = () => {
    loader(true);
    var packageId = localStorage.getItem("packageId");
    var subPackageId = localStorage.getItem("subPackageId");
    var userData = JSON.parse(localStorage.getItem("userLoginDetails"));

    var payload = {
      userName: values?.userName,
      userGender: values?.userGender,
      userId: userData?.uuid,
      userAge: values?.userAge,
      subPackageId: subPackageId,
      boardingPointId: values?.boardingPoint,
      packageId: packageId,
      bookingDate: values?.date
        ? values?.date?.split("-").reverse().join("-")
        : "",
      dropingPointId: values?.droppingPoint,
      userPhoneNumber: values?.mobileNo,
      userAlternatePhoneNumber: values?.alternateMobileNo,
      userMailId: values?.emailId,
      noOfAdults: values?.adults ? values?.adults : 0,
      noOfChilds: values?.children ? values?.children : 0,
      passengerDetails: [
        ...values?.passengerDetails,
        ...values?.passengerDetails2,
      ],
      selectedAmount: values?.selectedAmount,
    };
    console.log("Valuesfromsubmit", payload);
    moduleApi
      .postData(apiCall?.saveUserTripDetails, payload)
      .then((response) => {
        console.log(response);
        // setOrderId(response.data.order_id)
        if (response?.status === 200) {
          try {
            let checkoutOptions = {
              paymentSessionId: response?.data?.payment_session_id,
              redirectTarget: "_modal",
            };

            cashfree.checkout(checkoutOptions).then((res) => {
              console.log(res, "payment initialized");

              verifyPayment(
                response?.data?.order_id,
                response?.data?.bookingId
              );
            });
          } catch (error) {
            console.log(error);
          }
        } else {
          loader(false);
          return swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  // const [orderId, setOrderId] = useState("")

  // const getSessionId = async () => {
  //   try {
  //     let res = await moduleApi.getData(apiCall.getSessionId)

  //     if(res.data && res.data.payment_session_id){

  //       console.log(res.data)
  //       setOrderId(res.data.order_id)
  //       return res.data.payment_session_id
  //     }

  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const verifyPayment = async (orderId, bookingId) => {
    try {
      let res = await moduleApi.postData(apiCall.verifyPayment, {
        orderid: orderId,
        bookingId: bookingId,
      });

      if (
        res?.status === 200 &&
        res?.data &&
        res?.data?.payment_status &&
        res?.data?.payment_status === "SUCCESS"
      ) {
        loader(false);
        navigate("/tour-packages");
        return swal("Your Trip has been Booked Successfully!", {
          icon: "success",
        });
      } else {
        loader(false);
        return swal(res?.message, {
          icon: "error",
          dangerMode: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handleClick = async (e) => {
  //   e.preventDefault()
  //   try {

  //     let sessionId = await getSessionId()
  //     let checkoutOptions = {
  //       paymentSessionId : sessionId,
  //       redirectTarget:"_modal",
  //     }

  //     cashfree.checkout(checkoutOptions).then((res) => {
  //       console.log(res,"payment initialized")

  //       verifyPayment(orderId)
  //     })

  //   } catch (error) {
  //     console.log(error)
  //   }

  // }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDropdownOpen = (field) => () => {
    setDropdownOpen((prev) => ({ ...prev, [field]: true }));
  };

  const handleDropdownClose = (field) => () => {
    setDropdownOpen((prev) => ({ ...prev, [field]: false }));
  };

  const getDetailsForPayment = () => {
    const userData = JSON.parse(localStorage.getItem("userLoginDetails"));
    console.log(userData);
    if (userData) {
      onSubmit();
    } else {
      sendOtpForMobileVerification();
    }
  };

  const sendOtpForMobileVerification = () => {
    var payload = {
      mobileNumber: values.mobileNo,
      name: values.userName,
      email: values.emailId,
      gender: values.userGender,
      age: values.userAge,
    };
    moduleApi
      .postData(apiCall.sendOtpForTripMobileVerification, payload)
      .then((response) => {
        if (response?.message) {
          setTempIdForUserVerification(response?.data);
          loader(true);
          setLoginModal(true);
          return toaster("success", response?.message);
        } else {
          toaster("error", "Something went wrong, please try again later...");
        }
      });
  };

  const getOtpVerification = () => {
    if (enteredOtp === "") {
      return swal("Please Enter OTP", {
        icon: "error",
        dangerMode: true,
      });
    }

    var payload = {
      phoneNumber: values?.mobileNo,
      otp: enteredOtp,
      id: tempIdForUserVerification,
    };
    console.log(payload);
    moduleApi.postData(apiCall.verifyOtp, payload).then((response) => {
      if (response?.message === "Please enter valid OTP") {
        return swal("Please Enter Valid OTP", {
          icon: "error",
          dangerMode: true,
        });
      } else if (response?.data) {
        authenticate(true);
        localStorage.setItem(
          "userLoginDetails",
          JSON.stringify(response?.data)
        );
        setLoginModal(false);
        loader(false);
        setTempIdForUserVerification(null);
        setEnteredOtp("");
        return swal("Mobile Number Verified and Logged in Successfully!", {
          icon: "success",
        });
      }
    });
  };

  // const handleLoginModal = () => {
  //   setLoginModal(false);
  //   setSignupModal(false);
  //   authenticate(true);
  // };
  // console.log(isMatch);

  // const handleSignUp = () => {
  //   setLoginModal(false);
  //   setSignupModal(true);
  // };

  useEffect(() => {
    const totalAdults = parseInt(values.adults || 0, 10);
    const totalChildren = parseInt(values.children || 0, 10);
    // const totalPersons =
    //   parseInt(values.adults || 0, 10) + parseInt(values.children || 0, 10);

    setValues((prev) => ({
      ...prev,
      names: Array(totalAdults).fill(""),
      genders: Array(totalAdults).fill(""),
      ages: Array(totalAdults).fill(""),
      childNames: Array(totalChildren).fill(""),
      childGenders: Array(totalChildren).fill(""),
      childAges: Array(totalChildren).fill(""),
      passengerDetails: Array.from({ length: totalAdults }, (_, index) => ({
        name: prev.passengerDetails[index]?.names || "",
        gender: prev.passengerDetails[index]?.genders || "",
        age: prev.passengerDetails[index]?.ages || "",
      })),
      passengerDetails2: Array.from({ length: totalChildren }, (_, index) => ({
        name: prev.passengerDetails2[index]?.childNames || "",
        gender: prev.passengerDetails2[index]?.childGenders || "",
        age: prev.passengerDetails2[index]?.childAges || "",
      })),
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      names: Array(totalAdults).fill(""),
      genders: Array(totalAdults).fill(""),
      ages: Array(totalAdults).fill(""),
      childNames: Array(totalChildren).fill(""),
      childGenders: Array(totalChildren).fill(""),
      childAges: Array(totalChildren).fill(""),
    }));
  }, [values.adults, values.children]);

  return (
    <div>
      <div style={{ minHeight: "81vh" }}>
        <div
          className="card"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            padding: "10px",
            gap: "10px",
            margin: "10px",
            // maxWidth:'1500px',
            paddingTop: "20px",
            boxShadow: "1px 1px 5px #797979",
          }}
        >
          <span
            style={{ color: "#443bb7", fontSize: "larger", fontWeight: "bold" }}
          >
            {packageInfo?.packTitle}
          </span>

          <hr width="100%" size="1" style={{ marginBottom: "5px" }}></hr>
          <Stepper
            activeStep={activeStep}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "30px",
            }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <Typography
                variant="h6"
                gutterBottom
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "20px",
                }}
              >
                Redirecting to Payment
                <img src={onlinePayment} alt="" />
              </Typography>
            ) : (
              <div>
                {getStepContent(
                  activeStep,
                  values,
                  handleChange,
                  errors,
                  dropdownOpen,
                  handleDropdownOpen,
                  handleDropdownClose,
                  boardingPoints,
                  droppingPoints,
                  packageInfo,
                  getBoardingPointLabelUi
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                >
                  <Button disabled={activeStep === 0} onClick={handleBack}>
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      activeStep === steps.length - 1
                        ? getDetailsForPayment()
                        : handleNext();
                    }}
                  >
                    {activeStep === steps.length - 1
                      ? isAuthenticated === true
                        ? "Proceed to Payment"
                        : "Proceed to Mobile Verification"
                      : "Next"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal className="fade" show={loginModal} centered>
        <Modal.Header>
          <Modal.Title>
            <h3>Mobile Number Verification </h3>
          </Modal.Title>
          <ClearIcon
            style={{ marginRight: "5px", cursor: "pointer" }}
            onClick={() => {
              loader(false);
              setLoginModal(false);
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <div
            className="App"
            style={{
              margin: "20px",
              padding: "15px",
              borderRadius: "12px",
              border: "1px solid #dae1e5",
            }}
          >
            <h2>OTP Verification</h2>
            <div style={{ marginTop: "10px" }}>
              <TextField
                label={`OTP`}
                type="number"
                value={enteredOtp}
                onChange={(e) => {
                  setEnteredOtp(e.target.value);
                }}
                placeholder="Enter Otp"
                margin="normal"
                fullWidth
              />
            </div>
            <Button
              style={{
                backgroundColor: "#1976d2",
                padding: "6px 16px",
                borderRadius: "4px",
                boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
                marginLeft: "10px",
                marginTop: "10px",
                color: "#fff",
              }}
              variant="contained"
              onClick={() => getOtpVerification()}
            >
              Submit
            </Button>
          </div>
          {/* <Login
            updateAuthentication={handleLoginModal}
            updateSignUp={handleSignUp}
            mobileNumber={values?.mobileNo}
          /> */}
        </Modal.Body>
      </Modal>
      {/* <Modal className="fade" show={signupModal} style={{ marginTop: "40px" }}>
        <Modal.Header>
          <Modal.Title>
            <h3>Register</h3>
          </Modal.Title>
          <ClearIcon
            style={{ marginRight: "5px", cursor: "pointer" }}
            onClick={() => setSignupModal(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: "400px", overflowX: "auto" }}>
            <Signup updateAuthentication={handleLoginModal} />
          </div>
        </Modal.Body>
      </Modal> */}
      <div style={{ flex: 1 }}></div>
    </div>
  );
};

export default NewForm;
