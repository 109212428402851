import React from "react";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import FeedIcon from "@mui/icons-material/Feed";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import {
  Button,
} from "@mui/material";
import noImage2 from "../assets/noImage2.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import CarouselImages from "./CarouselImages";

const DetailsCard = ({ inputData, type }) => {
  let navigate = useNavigate();

  const handleBookNow = (plans) => {
    console.log(plans);
    localStorage.setItem("planDetails", JSON.stringify(plans));
    navigate("/form");
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: "25px",
        marginBottom: "10px",
        borderWidth: "1px",
        boxShadow: "1px 1px 5px #797979",
      }}
      className="card"
    >
      <div
        // class=" section-header text-center"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-left",
          padding: "10px",
          rowGap: "5px",
        }}
      >
        {/* <h5
          class="text-center headdingcolor"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        > */}

        {type === "thumb" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              width: "100%",
            }}
          >
             <CarouselImages images={inputData?.banners}/>
          </div>
        )}

        {type === "packageInfo" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginBottom: "10px",
                // alignItems: "center",
              }}
            >
              <FeedIcon style={{ marginRight: "5px", marginTop: "3px" }} />{" "}
              <h2>Package Info</h2>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: inputData?.packageInfo?.description,
              }}
            />
          </div>
        )}
        {type === "itenerary" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginBottom: "10px",
                alignItems: "center",
              }}
            >
              <TextSnippetIcon style={{ marginRight: "5px" }} />
              <h2>Itinerary</h2>
            </div>
            {/* <div
              dangerouslySetInnerHTML={{ __html: inputData?.itenerary?.data }}
            /> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "flex-start",
              }}
            >
              {inputData?.itenerary
                ?.sort((a, b) => a.order - b.order) 
                .map((item, index) => (
                  <div key={index} style={{ marginBottom: "15px" }}>
                    <span style={{ fontWeight: "bold" }}>
                      {item?.stepperTitle}
                    </span>{" "}
                    <ol
                      style={{
                        alignSelf: "flex-start",
                        paddingLeft: "20px",
                        margin: 0,
                      }}
                    >
                      <div
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                      /> 
                      {/* {item?.description
                        ?.split("\r\n")
                        .map((descItem, descIndex) => (
                          <li
                            key={descIndex}
                            style={{ marginBottom: "8px", color: "#555" }}
                          >
                            {descItem} 
                          </li>
                        ))} */}
                    </ol>
                  </div>
                ))}
            </div>
          </div>
        )}

        {type === "inclusions" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginBottom: "10px",
                alignItems: "center",
              }}
            >
              <CheckIcon style={{ marginRight: "5px" }} />
              <h2>Inclusions</h2>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: inputData?.inclusions?.data }}
            />
          </div>
        )}
        {type === "exclusions" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginBottom: "10px",
                alignItems: "center",
              }}
            >
              <ClearIcon style={{ marginRight: "5px" }} />
              <h2>Exclusions</h2>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: inputData?.exclusions?.data }}
            />
            {/* <ol
              style={{
                alignSelf: "flex-start",
                paddingLeft: "20px",
                margin: 0,
              }}
            >
              {inputData?.exclusions?.data?.split("\r\n").map((item) => (
                <li
                  style={{
                    alignItems: "flex-start",
                    marginBottom: "8px",
                    color: "#555",
                  }}
                >
                  {item}
                </li>
              ))}
            </ol> */}
          </div>
        )}


        {type === "subPacks" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginBottom: "10px",
                // alignItems: "center",
              }}
            >
              <NewspaperIcon style={{ marginRight: "5px", marginTop: "3px" }} />{" "}
              <h2>Sub Packages</h2>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <Section id="recommend" style={{ minHeight: "80vh" }}>
                <div className="destinations">
                  {inputData?.subPacks?.map((pack, index) => {
                    return (
                      <div
                        style={{ cursor: "pointer" }}
                        key={index}
                        className="destination"
                      >
                        <img
                          src={pack.sub_thumb || noImage2} // Initial source
                          alt={pack.sub_title || "No data available"}
                          onError={(e) => {
                            e.target.onerror = null; // Prevent infinite loop in case `noImage` also fails
                            e.target.src = noImage2; // Fallback image if the original fails
                          }}
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                        <h3>{pack.sub_title}</h3>
                        <p>{pack.sub_subtitle}</p>
                        <div className="plans-container">
                          <div className="plans">
                            {pack?.plans?.map((plan) => (
                              <>
                                <div key={plan.planID} className="plan">
                                  <h4>
                                    {plan.planFor.charAt(0).toUpperCase() +
                                      plan.planFor.slice(1)}
                                  </h4>
                                  <p>Plan Amount: Rs {plan.planAmount}</p>
                                  <p
                  style={{
                    fontSize: "14px",
                    color: "#666",
                    textDecoration: "line-through", // Strikethrough style
                    margin: "5px 0", // Space around the actual amount
                  }}
                >
                  Actual Amount: Rs {plan.actualAmount}
                </p>
                                </div>
                              </>
                            ))}
                          </div>
                          <Button
                            style={{
                              backgroundColor: "#09BD3C",
                              borderColor: "09BD3C",
                              maxHeight: "2rem",
                              border: "1px solid rgba(0, 0, 0, 0.125)",
                              boxShadow: "rgb(121, 121, 121) 1px 1px 5px",
                              borderRadius: "1.25rem",
                              color: "white",
                              marginTop: "10px",
                              fontSize: "medium",
                              fontWeight: "600",
                              fontFamily:
                                " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                            }}
                            className="me-2"
                            variant="success btn-rounded"
                            onClick={() => handleBookNow(pack.plans)}
                          >
                            Book Now
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Section>
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default DetailsCard;

const Section = styled.section`
  padding: 2rem 0;
  .title {
    text-align: center;
  }
  .packages {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
    ul {
      display: flex;
      list-style-type: none;
      width: max-content;
      li {
        padding: 1rem 2rem;
        border-bottom: 0.1rem solid black;
      }
      .active {
        border-bottom: 0.5rem solid #8338ec;
      }
    }
  }
  .destinations {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    padding: 0 3rem;
    margin-bottom: 15px;
    .destination {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      background-color: #8338ec14;
      border-radius: 1rem;
      transition: 0.3s ease-in-out;
      &:hover {
        transform: translateX(0.4rem) translateY(-1rem);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
      img {
        width: 100%;
      }
      .info {
        display: flex;
        align-items: center;
        .services {
          display: flex;
          gap: 0.3rem;
          img {
            border-radius: 1rem;
            background-color: #4d2ddb84;
            width: 2rem;
            /* padding: 1rem; */
            padding: 0.3rem 0.4rem;
          }
        }
        display: flex;
        justify-content: space-between;
      }
      .distance {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 768px) {
    .packages {
      ul {
        li {
          padding: 0 0.5rem;
          font-size: 2vh;
          padding-bottom: 1rem;
        }
        .active {
          border-bottom-width: 0.3rem;
        }
      }
    }
    .destinations {
      grid-template-columns: 1fr;
      padding: 0;
    }
  }

  /* Style for the plans container */
  .plans {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }

  /* Each individual plan */
  .plan {
    flex: 1;
    padding: 0 10px;
    border-left: 1px solid #ccc;
  }

  .plan:first-child {
    border-left: none;
  }

  .plan h4 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }

  .plan p {
    font-size: 14px;
    margin: 5px 0;
    color: #555;
    line-height: 1.5;
  }

  /* Destination styling */
  .destination {
    text-align: center;
    margin-bottom: 30px;
  }

  .destination img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }

  .destination h3 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    color: #333;
  }

  .destination p {
    font-size: 16px;
    color: #777;
  }

  /* Ensure that the destination is centered and plans are evenly spaced */
  .plans-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
`;
