import React from "react";
import EventNoteIcon from "@mui/icons-material/EventNote";


const PlansComponent = ({ inputData, type }) => {
    return (
      <>
        {type === "plans" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px",
              backgroundColor: "#f5f2ff", // Light background color
              borderRadius: "8px",
            //   maxWidth: "600px",
              margin: "auto", // Center the card horizontally
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginBottom: "10px",
                alignItems: "center",
              }}
            >
              <EventNoteIcon style={{ marginRight: "5px" }} />
              <h2>Plans</h2>
            </div>
  
            {/* Plan Container */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#fff",
                padding: "20px",
                borderRadius: "12px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)", // Subtle shadow for elevation
                width: "100%",
              }}
            >
              {inputData?.plans?.map((plan) => (
                <div
                  key={plan.planID}
                  style={{
                    flex: 1,
                    textAlign: "center",
                    borderRight: plan.planFor !== "child" ? "1px solid #ddd" : "none", // Border between columns
                  }}
                >
                  {/* Plan For */}
                  <h4
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#333",
                      marginBottom: "10px",
                    }}
                  >
                    {plan.planFor.charAt(0).toUpperCase() + plan.planFor.slice(1)}
                  </h4>
  
                  {/* Plan Amount */}
                  <p style={{ fontSize: "14px", color: "#666", fontWeight: "bold" }}>Plan Amount:
                    Rs {plan.planAmount}
                  </p>

                  <p
                  style={{
                    fontSize: "14px",
                    color: "#666",
                    textDecoration: "line-through", // Strikethrough style
                    margin: "5px 0", // Space around the actual amount
                  }}
                >
                  Actual Amount: Rs {plan.actualAmount}
                </p>
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    );
  };
  
  export default PlansComponent;